import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-inline-errors',
  templateUrl: './inline-errors.component.html',
  styleUrls: ['./inline-errors.component.scss'],
})
export class InlineErrorsComponent implements OnInit {
  @Input() errors: Array<any>;
  @Input() backgroundWhite: boolean;

  ngOnInit() {}
}
